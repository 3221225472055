
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import PProgressBar from "primevue/progressbar";

import { BankForm } from "@/store/bank/types";
import { isEditFormByRouteName } from "@/libs/utils";

const VBankForm = defineComponent({
  name: "VBankForm",
  components: { CModal, CFormInputText, PProgressBar },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id);
    const isEditForm = isEditFormByRouteName(route.name);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} banco | obmed` });

    const bank = computed(() => store.state.bank.current);
    const form = reactive<BankForm>({ nm_banco: "", ds_codigo: "", ds_sigla: "" });
    const loading = reactive({ bank: false, submit: false, city: false, state: false });

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      loading.submit = true;
      const response = isEditForm
        ? await store.dispatch("updateBank", { _id, form })
        : await store.dispatch("createBank", { form });
      loading.submit = false;

      if (response?.status === 200 || response?.status === 201) {
        store.dispatch("getBanks");
        router.replace({ name: "bank-list" });
      }
    }

    async function getBank() {
      loading.bank = true;
      await Promise.allSettled([store.dispatch("getBank", { _id })]);
      loading.bank = false;
    }

    async function handleApplyForm() {
      await getBank();

      form.nm_banco = bank.value?.nm_banco || "";
      form.ds_codigo = bank.value?.ds_codigo || "";
      form.ds_sigla = bank.value?.ds_sigla || "";
    }

    if (isEditForm) handleApplyForm();

    return { form, loading, isEditForm, handleClose, onSubmit };
  },
});

export default VBankForm;
